// src/pages/business.js
import * as React from 'react';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import CtrSection from '../components/CtrSection/CtrSection';
import FeatureSectionLeft from '../components/FeatureSectionLeft/FeatureSectionLeft';
import FeatureSectionRight from '../components/FeatureSectionRight/FeatureSectionRight';
import TextImageSection from '../components/TextImageSection/textImageSection';
import Layout from '../components/layout';
import Seo from '../components/seo';

const BusinessPage = ({ data }) => {
  return (
    <Layout>
      <TextImageSection
        subtitle="Business"
        title="事業内容"
        text="デジタルクリエイティブは、様々な飲食店様向けに適切なWebサイトの設計、広告運用をサポートしてまいります。"
        imageUrl="/images/haikei3.jpg"
        imageAlt="ホームページ制作"
      />
      <Breadcrumb
        crumbs={[
          { pathname: '/', crumbLabel: 'TOP' },
          { pathname: '/about', crumbLabel: '企業概要' },
          { pathname: '/business', crumbLabel: '事業内容' },
        ]}
      />
      <FeatureSectionRight
        imageSrc="/corporate/website.jpg"
        imageAlt="Example image"
        title="飲食店特化のホームページ制作"
        subTitle="Web Design"
        description={`
        ホームページは、飲食店の顔ともいえる重要なツールです。
        弊社では、飲食店に特化したことによるデザイン力が強みです。
        SEO対策による検索エンジンでの上位表示も意識したホームページを制作しています。
        過去には「サガリ肉」で検索一位表示を実現した実績もあります。
        企業の魅力を存分に伝えられるよう、分かりやすく使いやすいサイトづくりを心がけています。
      `}
        sectionId="section1"
        ctrText="詳しくはこちら"
        ctrLink="/flow"
      />
      <FeatureSectionLeft
        imageSrc="/corporate/ecsite2.jpg"
        imageAlt="Example image"
        title="ECサイト制作"
        subTitle="EC Site Design"
        description={`
        飲食店様向けのECサイト制作を行っています。
        オンラインショップを開設することで、
        飲食店は店舗ブランドの商品を継続的に販売することが可能です。
        `}
        sectionId="ec-section"
      />
      <FeatureSectionRight
        imageSrc="/corporate/ITコンサル.jpg"
        imageAlt="Example image"
        title="スマートな予約管理"
        subTitle="Reservation System"
        description={`
        使いやすい予約管理システムを導入しています。
        24時間いつでも予約が可能です。
        カレンダー機能やメール自動送信機能を備え、効率的な予約運営を実現します。管理者側と利用者側の両方の利便性を追求したシステムとなっています。
        スタッフの手間を省き、お客様にも便利な予約システムを提供します。
      `}
      />
      <CtrSection />
    </Layout>
  );
};

export default BusinessPage;

export const Head = () => (
  <Seo
    title="事業内容"
    description="デジタルクリエイティブ会社は飲食店のデジタル化を支援します。飲食店特化のホームページ制作、ECサイト制作、予約管理システムの導入をサポートしています。"
    cover="cover/business.jpg"
  />
);
